import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { uploadToS3 } from "../../utils/helpers";
import { Col, Form, Row, Button } from "react-bootstrap";
import { ArrowIcon } from "../../components/SvgIcons";
import { FadeLoader } from "react-spinners";
import { CreateTestimonials } from "../../services/testimonials.service";
import { cross_origin } from "../../utils/constants";
import { constants } from "../../utils/constants";
import { TrashIcon } from "../../components/SvgIcons";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";

function AddNewTestimonials(props) {
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [image, setImage] = useState("");
  const [file, setFIle] = useState(null);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [equipmentImageFile, setEquipmentImageFile] = useState(null);
  const [isUploadEquipmentImage, setUploadEquipmentImage] = useState(false);
  const [equipmentImage, setEquipmentImage] = useState("");
  const [folderName, setfolderName] = useState("5. Testimonial/");
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [img, setImg] = useState("");

  const {
    register,
    watch,
    setError,
    clearErrors,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    setLoader(true);

    if (!img) {
      setLoader(false);
      setError("image", { message: "Image is required" });
      return false;
    }

    data.image = img;

    await CreateTestimonials(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/testimonials");
        } else {
          setLoader(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const selectedMedia = (fileName, file) => {
    console.log("file====>", file);
    clearErrors(fileName);
    setImg(file);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const uploadFile = async (fileInput) => {
    let fileUrl = URL.createObjectURL(fileInput[0]);
    setImage(fileUrl);
    setFIle(fileInput[0]);
    setIsUpload(true);
    /*let uploadImage = await UploadImageToS3(fileInput[0]);
        if (uploadImage) {
            if (!uploadImage.success) {
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            toast.error('Can\'t Upload Image');
            return false;
        }*/
  };
  const deleteEquipmentImage = (type) => {
    setEquipmentImageFile(null);
    setEquipmentImage(null);
  };
  const handleEquipmentImage = async (event) => {
    setEquipmentImageFile(URL.createObjectURL(event.target.files[0]));
    setEquipmentImage(event.target.files[0]);
    setUploadEquipmentImage(true);
  };

  const BackTestimonials = () => {
    history.push("/testimonials");
  };

  // Custom validation for trailing spaces
  const validateTrailingSpaces = (value) => {
    if (value.endsWith(" ")) {
      return "Input cannot have trailing spaces.";
    }
    return true;
  };

  return (
    <div className="testimonials-index">
      <div className="page-title">
        <h1>Add New Testimonials</h1>
        <Button onClick={BackTestimonials} className="btn common-btn">
          Back
        </Button>
      </div>

      <div className="common-form">
        <Form
          className="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <Form.Group className="mb-3" controlId="full_name">
            <Form.Label>Image*</Form.Label>
            <input
              {...register("image")}
              onChange={(e) => uploadFile(e.target.files)}
              type="file"
              // className='btn common-btn'
              id="image"
            />
            {errors.image && (
              <Form.Text className="text-muted validationText hasError">
                {errors.image.message}
              </Form.Text>
            )}
            {isUpload ? (
              <img
                src={image}
                className={"img-table img-thumbnail"}
                crossOrigin={cross_origin ? "anonymous" : null}
              />
            ) : (
              ""
            )}
          </Form.Group> */}
          <Row>
            <Col md={3}>
              <Form.Group
                controlId="formFile"
                onClick={() => {
                  setFileName("image");
                  setModalShow(true);
                }}
                //onChange={(e) => handleEquipmentImage(e)}
                className="custom-file-upload mb-3"
              >
                <Form.Label className={"btn common-btn"}>
                  Upload Image
                </Form.Label>
                {/* <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  {...register("image")}
                /> */}
              </Form.Group>
              {equipmentImageFile ? (
                <>
                  <div className="image-item">
                    <img
                      crossOrigin={cross_origin ? "anonymous" : null}
                      className={"img-table img-thumbnail"}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      src={equipmentImageFile}
                      alt={"Image"}
                    />
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteEquipmentImage("image")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name*</Form.Label>
            <Form.Control
              placeholder="Enter Name"
              {...register("name", {
                required: {
                  value: "required",
                  message: "Name is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 25,
                  message: "Max length is 25",
                },
                validate: validateTrailingSpaces,
              })}
              type="text"
            />
            {errors.name && (
              <Form.Text className="text-muted validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="designation">
            <Form.Label>Designation*</Form.Label>
            <Form.Control
              placeholder="Enter Designation"
              {...register("title", {
                required: {
                  value: "required",
                  message: "Designation is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 30,
                  message: "Max length is 30",
                },
              })}
              type="text"
            />
            {errors.title && (
              <Form.Text className="text-muted validationText hasError">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description*</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              {...register("description", {
                required: {
                  value: "required",
                  message: "Description is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 450,
                  message: "Max length is 450",
                },
              })}
            />
            {errors.description && (
              <Form.Text className="text-muted validationText hasError">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>

          <Row>
            <Col className="mt-3 ms-auto" xl={3} xxl={2}>
              {loader ? (
                <div className="spin-loader">
                  <FadeLoader color={"#EC1246"} height={10} />
                </div>
              ) : (
                <Button
                  type={"submit"}
                  className={"btn common-btn px-0 w-100 mt-3"}
                  value={"Create Testimonials"}
                >
                  Create Testimonials
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={"images"}
            folderName={folderName}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default AddNewTestimonials;
