import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadImageToS3, uploadToS3 } from "../../utils/helpers";
import {
  GetEquipmentById,
  UpdateEquipment,
} from "../../services/equipments.service";
import {
  GetTestimonialsById,
  UpdateTestimonials,
} from "../../services/testimonials.service";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon, TrashIcon } from "../../components/SvgIcons";
import { constants, cross_origin } from "../../utils/constants";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";

function EditTestimonials(props) {
  const id = props.match.params.id;
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [row, setRow] = useState(null);
  const [fileMaleImage, setFileMaleImage] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [male_image, setmale_image] = useState(null);
  const [folderName, setfolderName] = useState("5. Testimonial/");
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [img, setImg] = useState("");

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    GetTestimonialsById(id)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setRow(result.data);
            setFormData(result.data);
            setValue("name", result.data.name);
            setValue("title", result.data.title);
            setValue("image", result.data.image);
            setValue("description", result.data.description);
            setImg(result.data.image);
            setIsUpload(true);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  }, []);
  const onSubmit = async (data) => {
    setLoader(true);
    if (!img) {
      setError("image", { message: "Image is required" });
      setLoader(false);
      return false;
    } else if (img) {
      data.image = img;
    }

    await UpdateTestimonials(id, data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/testimonials");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  // const uploadFile = async (fileInput) => {
  //   let fileUrl = URL.createObjectURL(fileInput[0]);
  //   setImage(fileUrl);
  //   setFile(fileInput[0]);
  //   setIsUpload(true);
  // };
  const handleMaleImageChange = async (event) => {
    setFileMaleImage(URL.createObjectURL(event.target.files[0]));
    setmale_image(event.target.files[0]);
  };
  const deleteMaleImage = (fileName) => {
    setFormData({ ...formData, ...{ [fileName]: "" } });
    setmale_image("");
    setFileMaleImage(null);
  };

  const BackTestimonials = () => {
    history.push("/testimonials");
  };

  const selectedMedia = (fileName, file) => {
    console.log("file====>", file);
    clearErrors(fileName);
    setImg(file);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  // Custom validation for trailing spaces
  const validateTrailingSpaces = (value) => {
    if (value.endsWith(" ")) {
      return "Input cannot have trailing spaces.";
    }
    return true;
  };

  return (
    <div className="testimonials-index">
      <div className="page-title">
        <h1>Edit Testimonials</h1>
        <Button onClick={BackTestimonials} className="btn common-btn">
          Back
        </Button>
      </div>
      <div className="common-form">
        <Form
          className="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* <Form.Group
            controlId="formFile"
            onChange={(e) => handleMaleImageChange(e)}
            className="custom-file-upload mb-3"
          >
            <Form.Label className={"common-btn btn"}>Image*</Form.Label>
            <Form.Control
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif"
              {...register("male_image")}
              onChange={(e) => uploadFile(e.target.files)}
              {...register("image")}
              // className='btn common-btn'
              id="image"
            />
            {errors.image && (
              <Form.Text className="text-muted validationText hasError">
                {errors.image.message}
              </Form.Text>
            )}
            {isUpload ? (
              <div className="image-item">
                <img
                  src={fileMaleImage}
                  className={"img-table img-thumbnail"}
                  crossOrigin={cross_origin ? "anonymous" : null}
                />
              </div>
            ) : (
              ""
            )}
          </Form.Group> */}
          <Row>
            <Col sm={3}>
              <Form.Group
                controlId="formFile"
                onClick={() => {
                  setFileName("image");
                  setModalShow(true);
                }}
                //onChange={(e) => handleMaleImageChange(e)}
                className="custom-file-upload mb-0"
              >
                <Form.Label className={"common-btn btn"}>
                  Upload Image
                </Form.Label>
                {/* <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  {...register("male_image")}
                /> */}
                {errors.male_image && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.male_image.message}
                  </Form.Text>
                )}
              </Form.Group>
              {fileMaleImage ? (
                <>
                  <div className="image-item">
                    <img
                      crossOrigin={cross_origin ? "anonymous" : null}
                      className={"img-table img-thumbnail"}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      src={fileMaleImage}
                      alt={"Image"}
                    />
                    <div className="image-item__btn-wrapper">
                      <Button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteMaleImage("male_image")}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name*</Form.Label>
            <Form.Control
              placeholder="Enter Name"
              {...register("name", {
                required: {
                  value: "required",
                  message: "Name is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 25,
                  message: "Max length is 25",
                },
                validate: validateTrailingSpaces,
              })}
              type="text"
            />
            {errors.name && (
              <Form.Text className="text-muted validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="designation">
            <Form.Label>Designation*</Form.Label>
            <Form.Control
              placeholder="Enter Designation"
              {...register("title", {
                required: {
                  value: "required",
                  message: "Designation is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 30,
                  message: "Max length is 30",
                },
              })}
              type="text"
            />
            {errors.title && (
              <Form.Text className="text-muted validationText hasError">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description*</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              {...register("description", {
                required: {
                  value: "required",
                  message: "Description is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
                maxLength: {
                  value: 450,
                  message: "Max length is 450",
                },
              })}
            />
            {errors.description && (
              <Form.Text className="text-muted validationText hasError">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
          <Row>
            <Col xl={3} xxl={2} className="ms-auto">
              {loader ? (
                <div className="spin-loader">
                  <FadeLoader color={"#EC1246"} height={10} />
                </div>
              ) : (
                <Button
                  type={"submit"}
                  className={"btn common-btn w-100 mt-3"}
                  value={"Update Testimonials"}
                >
                  Update Testimonials
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={"images"}
            folderName={folderName}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default EditTestimonials;
