/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { genders } from "../../utils/constants";
import { TbFilterOff } from "react-icons/tb";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import VideoThumbnail from "../program/VideoThumbnail";
import { SearchIcon } from "../../components/SvgIcons";
import { HashLoader } from "react-spinners";
import { GetBodyTypes } from "../../services/body_types.service";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetEquipments } from "../../services/equipments.service";
import {
  GetIProgramExercises,
  UpdateExerciseCompilation,
  GetCompliedExercises,
} from "../../services/exercises.service";
import LevelWiseEditExercise from "../exercise/LevelWiseEditExercise";

function EditCompilation(props) {
  let history = useHistory();
  let location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const [programPreData, setProgramPreData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [programNewData, setProgramNewData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [program, setProgram] = useState();
  const [exercise, setExercise] = useState();
  const [keyword, setKeyword] = useState("");
  const [gender, setGender] = useState("");
  const [bodyType, setBodyType] = useState([]);
  const [bodyTypePre, setBodyTypePre] = useState([]);
  const [level, setLevel] = useState([]);

  const [equipment, setEquipment] = useState([]);
  const [exerciseType, setExerciseType] = useState([]);
  const [muscle, setMuscle] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [exerciseTypes, setExerciseTypes] = useState([]);
  const [muscles, setMuscles] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [dragged, setDragged] = useState(null);
  const [swapDraggedArray, setSwapDraggedArray] = useState(null);
  const [selectRef, setSelectRef] = useState([]);
  const videoContainerRef = useRef();
  const videoComponentRef = useRef();
  const dayWiseContainerRef = useRef();
  const [removeExercises, setRemoveExercises] = useState([]);
  const [isRestComponent, setIsRestComponent] = useState(false);
  const [dayId, setDayId] = useState("");
  const typingTimeoutRef = useRef(null);
  const [isComplete, setIsComplete] = useState(0);
  const [buttonType, setButtonType] = useState("");
  const [exerciseFilter, setExcerciseFilter] = useState("");
  const [statusFilter, setStatusWiseFilter] = useState([]);

  const handleKeyUp = (e) => {
    clearTimeout(typingTimeoutRef.current);
    const value = e.target.value.trim();
    const sanitizedInput = value.replace(/[^A-Za-z\s]+/g, "");
    // if (sanitizedInput.length > 2) {
    typingTimeoutRef.current = setTimeout(() => {
      setKeyword(sanitizedInput);
    }, 500);
    // } else {
    //   setKeyword(null);
    // }
  };

  if (location.state !== undefined && exercise === undefined) {
    setExercise(location.state.exercise);
    if (location.state.redirectToSummary) {
      location.state.redirectToSummary = false;
      // props.handleStep(12)
    }
  }

  const resetCurrentComponent = async () => {
    setIsRestComponent(true);
    setDayId("");
    setDragged(null);
    setExercises([]);

    setProgramPreData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setProgramNewData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);

    document.getElementById("formFilterProgram")?.reset();
    document.getElementById("formProgramExercise")?.reset();
  };

  useEffect(() => {
    resetCurrentComponent();
  }, [program, props.activeStep, props.dayId, props.day, props.activeStep]);

  useEffect(() => {
    setProgramNewData(programPreData);
  }, [programPreData]);

  useEffect(() => {
    const getBodyTypes = async () => {
      await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setBodyTypes(result.data);
          if (bodyType !== "" && bodyType !== null) {
            let editBodyTypePre = result.data.filter(
              (item, index) => item.id == bodyType
            );
            setBodyTypePre({
              value: editBodyTypePre[0] ? editBodyTypePre[0].id : "",
              label: editBodyTypePre[0] ? editBodyTypePre[0].name : "",
            });
          } else {
            setBodyTypePre(null);
          }
        }
      });
    };

    const getExerciseTypes = async () => {
      await GetExerciseTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setExerciseTypes(result.data);
        }
      });
    };

    const getMuscles = async () => {
      await GetMuscles("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setMuscles(result.data);
        }
      });
    };

    const getEquipmentList = async () => {
      await GetEquipments("", "", "", 0, "asc", "title").then((result) => {
        if (result.status && result.data) {
          setEquipments(result.data);
        }
      });
    };

    getBodyTypes();
    getExerciseTypes();
    getMuscles();
    getEquipmentList();
  }, []);

  const removeExerciseVideo = (targetId) => {
    const newPreData = [...programPreData];
    let removeExercisesArray = removeExercises;
    if (
      newPreData[targetId] !== undefined &&
      newPreData[targetId] !== null &&
      newPreData[targetId].id !== undefined &&
      newPreData[targetId].id !== null
    ) {
      removeExercisesArray.push(newPreData[targetId].id);
      setRemoveExercises(removeExercisesArray);
    }
    newPreData[targetId] = null;
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    newNewData[targetId] = null;
    setProgramNewData(newNewData);
  };

  const handleSave = () => {
    setButtonType("save");
  };

  const handleSummary = () => {
    setButtonType("summary");
  };

  const onSubmit = async (data) => {
    if (programNewData.length !== 0 && programNewData !== undefined) {
      setDisableButton(true);
      setLoader(true);
      await UpdateExerciseCompilation(props.match.params.id, {
        internal_notes: data.internal_notes,
        week: 1,
        circuit: 1,
        name: props.day,
        excerciseCompilation: programNewData,
        removeExercises: removeExercises,
        internal_notes: data.internal_notes,
        isComplete: isComplete,
      })
        .then(async (data) => {
          setIsRestComponent(true);
          await resetCurrentComponent();
          setDayId("");
          if (data.status) {
            setDisableButton(false);
            setLoader(false);
            toast.success(data.message);
            if (buttonType == "save") {
              history.push("/exercises");
            } else {
              history.push("/summary-excercise-compliation/" + exercise.id);
            }
          } else {
            toast.error(data.message.replace(/_/g, " "));
            setDisableButton(false);
            setLoader(false);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          setLoader(false);
          toast.error("Something went wrong. Please try again.");
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          }
        });
    } else {
      toast.error("Please select exercise videos");
    }
  };

  const getExercises = async () => {
    setLoading(true);
    setExercises([]);

    await GetIProgramExercises(
      keyword,
      bodyType,
      exerciseType,
      equipment,
      muscle,
      gender,
      level,
      exerciseFilter,
      statusFilter,
      "",
      "asc",
      "name"
    )
      .then((result) => {
        if (result.status) {
          const exerciseData = result.data;
          setExercises(exerciseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const getCompliedExercises = async () => {
    setExercises([]);
    await GetCompliedExercises(props.match.params.id)
      .then((result) => {
        setValue("internal_notes", result.data.internal_notes);
        setIsComplete(result.data.isComplete);

        if (result.status) {
          const exerciseData = result.data.exerciseCompilation;
          if (exerciseData.length > 0) {
            handlePreDataArray(exerciseData);
          }
          //setCompliedExercises(exerciseData)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCompliedExercises();
  }, [props.match.params.id]);

  useEffect(() => {
    getExercises();
  }, [
    keyword,
    bodyType,
    exerciseType,
    equipment,
    muscle,
    gender,
    program,
    level,
    exerciseFilter,
    statusFilter,
    props.activeStep,
    props.dayId,
    props.day,
  ]);

  const clearFilter = () => {
    setKeyword("");
    setBodyType([]);
    setLevel("");
    setEquipment([]);
    setMuscle([]);
    setGender("");
    setBodyTypePre([]);
    setExcerciseFilter([]);
    resetSelectInstances();
    setStatusWiseFilter("");
    setExerciseType("");
    document.getElementById("formFilterProgram").reset();
  };

  const setDraggedFunc = (data) => {
    setDragged(data);
    setSwapDraggedArray(null);
  };

  const onDragOverFunc = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragEnterFunc = (e) => {
    e.stopPropagation();
  };

  const onDropFunc = (e, equipment, levelNo) => {
    e.preventDefault();
    //   props.changed(true)
    const targetId = e.target.id.split("-")[1];
    if (targetId && swapDraggedArray && !dragged) {
      let oldIndex = parseInt(swapDraggedArray.exercise_no) - 1;
      let newIndex = parseInt(targetId) - 1;
      let tempData = swapDraggedArray;
      tempData.exercise_no = targetId;
      tempData.equipment = equipment;
      tempData.level = levelNo;
      const newPreData = [...programPreData];
      if (
        oldIndex != null &&
        oldIndex != -1 &&
        newIndex != null &&
        newIndex != -1
      ) {
        newPreData[oldIndex] = null;
        newPreData[newIndex] = tempData;
      }
      setProgramPreData(newPreData);

      const newNewData = [...programNewData];
      if (
        oldIndex != null &&
        oldIndex != -1 &&
        newIndex != null &&
        newIndex != -1
      ) {
        newNewData[oldIndex] = null;
        newNewData[newIndex] = tempData;
      }
      console.log("onDropFunc", newNewData);
      setProgramNewData(newNewData);
      setSwapDraggedArray(null);
      setDragged(null);
    } else if (e.target.className === "dropzone" && dragged) {
      const videoId = dragged.dataset.video_id;
      const levelId = dragged.dataset.level_id;
      setProgramDayNewDataFunc(videoId, targetId, levelId, equipment, levelNo);
      setDragged(null);
      setSwapDraggedArray(null);
    }
  };

  const setSwapDraggedFunc = (data) => {
    setDragged(null);
    setSwapDraggedArray(data);
  };

  const setProgramDayNewDataFunc = (
    exerciseId,
    targetId,
    levelId,
    equipment,
    levelNo
  ) => {
    if (exerciseId !== null && targetId !== null) {
      const exerciseData = exercises.filter((elem) => elem.id == exerciseId);

      let preData = {
        level: levelNo,
        equipment_no: equipment,
        exercise_id: exerciseId,
        exercise_no: targetId,
        position: 0,
        exercise_level_id: levelId,
        exerciseLevel: exerciseData[0]["exerciseLevels"][0]
          ? exerciseData[0]["exerciseLevels"][0]
          : null,
        feel_it_position: "TL",
        feel_it_show: true,
        text_position: "TR",
        text_show: true,
        exerciseLevels: exerciseData[0]["exerciseLevels"]
          ? exerciseData[0]["exerciseLevels"]
          : null,
        exercise: exerciseData[0] ? exerciseData[0] : null,
      };

      let newData = {
        level: levelNo,
        equipment_no: equipment,
        exercise_id: exerciseId,
        exercise_no: targetId,
        position: 0,
        exercise_level_id: levelId,
        exerciseLevel: exerciseData[0]["exerciseLevels"][0]
          ? exerciseData[0]["exerciseLevels"][0]
          : null,
        feel_it_position: "TL",
        feel_it_show: true,
        text_position: "TR",
        text_show: true,
        exerciseLevels: exerciseData[0]["exerciseLevels"]
          ? exerciseData[0]["exerciseLevels"]
          : null,
        exercise: exerciseData[0] ? exerciseData[0] : null,
      };

      handleNewDataArray(preData, newData, targetId);
      return true;
    }
    return false;
  };

  const setWeightFunc = (e) => {
    //   props.changed(true)
    const targetId = e.target.id.split("-")[1];
    const position = e.target.value;
    if (position !== null && targetId !== null) {
      const newNewData = [...programNewData];
      if (targetId - 1 !== -1) {
        newNewData[targetId - 1].position = position;
      } else {
        newNewData[targetId].position = position;
      }
      setProgramNewData(newNewData);
    }
  };

  const setFeelItOverlayFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    const position = data.position;

    if (position !== null && targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_position = position;
        tempArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempArray[targetId].feel_it_position = position;
        tempArray[targetId].feel_it_show = data.show;
      }
      setProgramNewData((programNewData) => tempArray);
    }
  };

  const setFeelImageShowFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    if (targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempArray[targetId].feel_it_show = data.show;
      }
      setProgramNewData(tempArray);

      let tempPreArray = programPreData;
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempPreArray[targetId].feel_it_show = data.show;
      }
      setProgramPreData(tempPreArray);
    }
  };

  const setTextOverlayFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    const position = data.position;
    if (position !== null && targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_position = position;
        tempArray[targetId - 1].text_show = data.show;
      } else {
        tempArray[targetId].text_position = position;
        tempArray[targetId].text_show = data.show;
      }
      setProgramNewData((programNewData) => tempArray);
    }
  };

  const setTextOverlayShowFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    if (targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_show = data.show;
      } else {
        tempArray[targetId].text_show = data.show;
      }
      setProgramNewData(tempArray);

      let tempPreArray = programPreData;
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].text_show = data.show;
      } else {
        tempPreArray[targetId].text_show = data.show;
      }
      setProgramPreData(tempPreArray);
    }
  };

  const handlePreDataArray = (array) => {
    console.log("array", array);
    // const newPreData = [...programPreData];
    const newPreData = [];
    array.map((item) => {
      newPreData[item.exercise_no - 1] = item;
    });
    setProgramPreData(newPreData);
  };

  const handleNewDataArray = (preData, newData, targetId) => {
    const newPreData = [...programPreData];
    if (targetId - 1 !== -1) {
      newPreData[targetId - 1] = preData;
    } else {
      newPreData[targetId] = preData;
    }
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    if (targetId - 1 !== -1) {
      newNewData[targetId - 1] = newData;
    } else {
      newNewData[targetId] = newData;
    }
    setProgramNewData(newNewData);
  };

  const swapArrayFunc = (data) => {
    const newPreData = [...programPreData];
    if (
      data.indexSource != null &&
      data.indexSource != -1 &&
      data.indexDestination != null &&
      data.indexDestination != -1
    ) {
      newPreData[data.indexDestination] = data.tempSource;
      newPreData[data.indexSource] = data.tempDestination;
    }
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    if (
      data.indexSource != null &&
      data.indexSource != -1 &&
      data.indexDestination != null &&
      data.indexDestination != -1
    ) {
      newNewData[data.indexDestination] = data.tempSource;
      newNewData[data.indexSource] = data.tempDestination;
    }
    setProgramNewData(newNewData);
  };

  const setBodyTypeFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setBodyType(data);
  };

  const setEquipmentFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setEquipment(data);
  };

  const setExerciseTypeFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setExcerciseFilter(data);
  };

  const setMuscleFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setMuscle(data);
  };
  const setLevelFilter = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setLevel(data);
  };

  const setExerciseFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setExerciseType(data);
  };

  const resetSelectInstances = () => {
    selectRef.map((item) => {
      if (item !== null) {
        item.clearValue();
      }
    });
    setSelectRef([null]);
  };

  const setIsRestComponentFunc = (data) => {
    setIsRestComponent(false);
  };

  const setStatusFilter = (selectedValue) => {
    setStatusWiseFilter(selectedValue ? selectedValue : "");
  };

  return (
    <>
      <section className="add-exercise edit-exercise">
        <div className="page-title">
          <h1>Update Exercise Compilation</h1>
          <Button href="/edit-exercise" className="common-btn">
            Back
          </Button>
        </div>
        <Form id="formFilterProgram" autoComplete="off">
          <div className="exercise-detail">
            {/* <div className="exercise-select">
              <Form.Label>Exercise</Form.Label>
              <Select
                placeholder="Select..."
                id={"exercise"}
                className="basic-multi-select"
                classNamePrefix="select"
                aria-label="exercise"
                onChange={(selectedOptions) => setExerciseFunc(selectedOptions)}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
                options={[
                  { value: "0", label: "Individual Exercise" },
                  { value: "1", label: "Linked/Complied Exercise" },
                ]}
              />
            </div> */}
            <div className="exercise-select">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                placeholder="Select Gender"
                id={"gender"}
                className="formselect program-dropdown"
                aria-label="gender"
                onChange={(e) => setGender(e.target.value)}
              >
                <option value={""} selected={true}>
                  Select Gender
                </option>
                <option value={genders.FEMALE}>Female</option>
                <option value={genders.MALE}>Male</option>
              </Form.Select>
            </div>
            <div className="exercise-select">
              <Form.Label>Body Type</Form.Label>
              <Select
                id={"body_type_id"}
                options={
                  bodyTypes &&
                  bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) => setBodyTypeFunc(selectedOptions)}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className="exercise-select">
              <Form.Label>Exercise Type</Form.Label>
              <Select
                id={"exercise_type"}
                options={
                  exerciseTypes &&
                  exerciseTypes.map((e) => ({ label: e.name, value: e.id }))
                }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  setExerciseTypeFunc(selectedOptions)
                }
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className="exercise-select">
              <Form.Label>Muscle</Form.Label>
              <Select
                id={"muscle"}
                options={
                  muscles &&
                  muscles.map((e) => ({ label: e.name, value: e.id }))
                }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) => setMuscleFunc(selectedOptions)}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className="exercise-select">
              <Form.Label>Equipment</Form.Label>
              <Select
                id={"equipment"}
                options={
                  equipments &&
                  equipments.map((e) => ({ label: e.title, value: e.id }))
                }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  setEquipmentFunc(selectedOptions)
                }
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className="exercise-select">
              <Form.Label>Levels</Form.Label>
              <Select
                placeholder="Select..."
                id={"exercise"}
                className="basic-multi-select"
                classNamePrefix="select"
                aria-label="exercise"
                onChange={(selectedOptions) => setLevelFilter(selectedOptions)}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
                options={[
                  { value: "4", label: "Level 0" },
                  { value: "1", label: "Level 1" },
                  { value: "2", label: "Level 2" },
                  { value: "3", label: "Level 3" },
                ]}
              />
            </div>
            <div className="exercise-select">
              <Form.Label>Status</Form.Label>
              <Form.Select
                placeholder="Select..."
                id={"excercise"}
                className="formselect program-dropdown"
                aria-label="excercise"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value={""} selected={true}>
                  Select...
                </option>
                <option value={"0"}>Incomplete</option>
                <option value={"1"}>Complete</option>
              </Form.Select>
            </div>
            <div className="search-bar custom-w mb-3">
              <div className="common-search-bar">
                <InputGroup className="search-group">
                  <InputGroup.Text id="basic-addon1">
                    <SearchIcon />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="Search"
                    maxLength={"255"}
                    onChange={(e) => handleKeyUp(e)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="exercise-select mb-3">
              <div>
                <Button
                  onClick={() => clearFilter()}
                  className="common-btn"
                  title={"Clear All Filters"}
                >
                  <TbFilterOff /> Clear All Filters
                </Button>
                {/* <ul className="list-inline">
                  <li
                    className="list-inline-item"
                    onClick={() => clearFilter()}
                  >
                    <span
                      style={{ cursor: "pointer", fontSize: "14px" }}
                      title={"Clear All Filters"}
                    >
                      <TbFilterOff /> Clear All Filters
                    </span>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </Form>
        <Row>
          {/* Thumbnail gallery starts*/}
          <Col md={6}>
            <div className="common-video">
              <div className="common-title">
                <h6>All Exercise</h6>
              </div>
              {loading ? (
                <div className="spin-loader mt-auto mb-auto">
                  <HashLoader color={"#EC1246"} height={10} />
                </div>
              ) : (
                <div className="thumbnail-gallery">
                  <div
                    className="exercise-video exercise-video-flex-custom"
                    ref={videoContainerRef}
                    id={"thumbnail-gallery-row"}
                  >
                    {exercises.length > 0 ? (
                      exercises.map((item, index) => {
                        return (
                          <VideoThumbnail
                            exercise={item}
                            ref={videoComponentRef}
                            index={index}
                            videoUrl={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].video
                                  ? item.exerciseLevels[0].video
                                  : ""
                                : ""
                            }
                            videoPoster={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].video_cover
                                  ? item.exerciseLevels[0].video_cover
                                  : ""
                                : ""
                            }
                            videoTitle={item && item.name ? item.name : ""}
                            addedBy={""}
                            link={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].video
                                  ? item.exerciseLevels[0].video
                                  : ""
                                : ""
                            }
                            key={item.id}
                            id={item.id}
                            level={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].id
                                  ? item.exerciseLevels[0].id
                                  : ""
                                : ""
                            }
                            exerciseFilter={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].id
                                  ? item.exerciseLevels[0].id
                                  : ""
                                : ""
                            }
                            statusFilter={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].id
                                  ? item.exerciseLevels[0].id
                                  : ""
                                : ""
                            }
                            dragged={setDraggedFunc}
                            flipVideo={
                              item && item.exerciseLevels[0]
                                ? item.exerciseLevels[0].video_flip
                                  ? item.exerciseLevels[0].video_flip
                                  : null
                                : null
                            }
                          />
                        );
                      })
                    ) : (
                      <div className="no-data-found">
                        <strong>No data found</strong>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="common-video">
              <div className="common-title">
                <h6>No. of Exercise</h6>
              </div>
              <div className="thumbnail-gallery exercise-video common-thumbnail-gallery 11">
                <LevelWiseEditExercise
                  dragged={setDraggedFunc}
                  onDragEnterFunc={onDragEnterFunc}
                  onDragOverFunc={onDragOverFunc}
                  onDropFunc={onDropFunc}
                  setWeightFunc={setWeightFunc}
                  removeExerciseVideo={removeExerciseVideo}
                  dayExerciseData={programPreData}
                  setFeelItOverlayFunc={setFeelItOverlayFunc}
                  setFeelImageShowFunc={setFeelImageShowFunc}
                  setTextOverlayPosFunc={setTextOverlayFunc}
                  setTextOverlayShowFunc={setTextOverlayShowFunc}
                  swapArray={swapArrayFunc}
                  swapDragged={setSwapDraggedFunc}
                  isRestComponent={isRestComponent}
                  setIsRestComponent={setIsRestComponentFunc}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="note">
          <Form
            id="formProgramExercise"
            className="formProgramExercise mt-2"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            {loader ? (
              <Row className="mt-4">
                <Col md={{ span: 2, offset: 5 }} className={"text-start"}>
                  <div className="spin-loader-screen">
                    <HashLoader color={"#EC1246"} height={10} />
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={isComplete}
                    id={"isComplete"}
                    className="form-select exercise-dropdown"
                    aria-label="IsComplete"
                    {...register("isComplete", {
                      onChange: (e) => {
                        setIsComplete(e.target.value);
                      },
                    })}
                  >
                    <option value="0">Incomplete</option>
                    <option value="1">Complete</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={4} xxl={5}>
                <Form.Group>
                  <Form.Label>Internal Notes </Form.Label>
                  <Form.Control
                    as="textarea"
                    className="exercise-input"
                    placeholder="Internal Notes"
                    id={"internal_notes"}
                    {...register("internal_notes", {
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                      //   onChange: (e) => props.changed(true)
                    })}
                    type="text"
                  />

                  {errors.internal_notes && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.internal_notes.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={1}>
                <Button
                  className="common-btn"
                  color="inherit"
                  type={"button"}
                  onClick={() =>
                    history.push("/edit-exercise/" + props.match.params.id)
                  }
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              </Col>
              <Col md={1}>
                <Button
                  id={"formProgramExerciseSubmitBtn"}
                  className={"common-btn mx-2"}
                  type={"submit"}
                  disabled={disableButton}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Col>
              <Col md={1}>
                <Button
                  id={"formProgramExerciseSubmitBtn"}
                  className={"common-btn"}
                  type={"submit"}
                  disabled={disableButton}
                  name={"summary"}
                  onClick={handleSummary}
                >
                  Summary
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </section>
      {/* form starts */}
    </>
  );
}

export default EditCompilation;
