import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { constants, customStyles, STATUS } from "../../utils/constants";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
  CreateSkipReasons,
  DeleteSkipReason,
  GetSkipReasonById,
  GetSkipReasons,
  UpdateSkipReason,
  DeleteMultipleSkipReason,
} from "../../services/skip_question_reasons.service";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MdEdit, MdDelete } from "react-icons/md";
import {
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function SkipQuestion() {
  let history = useHistory();
  const [reasonId, setSkipReasonId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const apiRef = useGridApiRef();

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  const {
    register,
    reset,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const fetchData = async () => {
      await getSkipReasonFunction(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getSkipReasonFunction(false);
    } else {
      getSkipReasonFunction(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("skip_quesition", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("skip_quesition", columns);
      } catch (error) { }
    };

    fetchData();
  }, []);

  // const getSkipReasonFunction = async (isInitialLoad = true) => {
  //   if (isInitialLoad) {
  //     setLoading(true);
  //   } else {
  //     setSearchLoading(true);
  //   }

  //   try {
  //     const result = await GetSkipReasons(keyword, page, perPage, 0);

  //     console.log('Result:', result); // Debug log

  //       if (result.data) {
  //         const rowData = result.data.map((dt) => ({
  //           id: dt?.id,
  //           option: dt?.option,
  //           status: STATUS[dt?.status],
  //         }));

  //         setPage(result.data.meta.current_page);
  //         setPageCount(result.data.meta.last_page);
  //         setRows(rowData);

  //     } else {
  //       toast.error(result.message);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error); // Debug log
  //     toast.error(
  //       (error.response?.data?.message.replace(/_/g, " ")) || "An error occurred 1"
  //     );
  //   } finally {
  //     if (isInitialLoad) {
  //       setLoading(false);
  //     } else {
  //       setSearchLoading(false);
  //     }
  //   }
  // };

  const getSkipReasonFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    try {
      const result = await GetSkipReasons(keyword, page, perPage, 1);

      if (result.status) {
        if (result.data) {
          const rowData = [];
          setPage(result?.data?.meta?.current_page);
          setPageCount(result?.data?.meta?.last_page);
          result?.data?.data?.map((dt) => {
            rowData.push({
              id: dt?.id,
              option: dt?.option,
              status: STATUS[dt?.status],
            });
          });
          setRows(rowData);
        }
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const onMultipleDelete = async () => {
    let rowsDeleted = await apiRef.current.getSelectedRows();

    const dataArray = [...rowsDeleted.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeSkipQuestions: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleSkipReason(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getSkipReasonFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  // Custom validation for trailing spaces
  const validateTrailingSpaces = (value) => {
    if (value.endsWith(" ")) {
      return "Input cannot have trailing spaces.";
    }
    return true;
  };

  const onSubmit = async (data) => {
    data.option = data.option.trim();
    setLoader(true);
    reset({
      option: "",
    });
    await CreateSkipReasons(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          setShowAddModal(false);
          setShowEditModal(false);
          getSkipReasonFunction();
          // reset({
          //     option: '',
          // })
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const onEditSubmit = async (data) => {
    setLoader(true);
    if (reasonId != 0 && reasonId != null) {
      data.option = data.option.trim();
      await UpdateSkipReason(reasonId, data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            toast.success(data.message);
            setShowAddModal(false);
            setShowEditModal(false);
            getSkipReasonFunction();
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    }
  };

  const EditModal = (props) => {
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Option*</Form.Label>
            <Form.Control
              {...register("option", {
                required: {
                  value: "required",
                  message: "Option is required",
                },
                validate: validateTrailingSpaces,
              })}
              type={"text"}
              placeholder={"Option"}
            />
            {errors.option && (
              <Form.Text className="validationText hasError">
                {errors.option.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"submit"}
            >
              submit
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const AddModal = () => {
    setSkipReasonId(null);
    setValue("option", "");
    return (
      <div className={"ViewModalContact"}>
        <Form
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Option*</Form.Label>
            <Form.Control
              {...register("option", {
                required: {
                  value: "required",
                  message: "Option is required",
                },
                validate: validateTrailingSpaces,
              })}
              type={"text"}
              placeholder={"Option"}
            />
            {errors.option && (
              <Form.Text className="validationText hasError">
                {errors.option.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Add New Option"}
            >
              Add New Option
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "#", width: 340 },
      { field: "option", headerName: "Option", width: 680 },
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onEdit = (id) => {
            reset({
              option: "",
            });
            setShowEditModal(true);
            setSkipReasonId(id);
            GetSkipReasonById(id)
              .then((result) => {
                if (result.status) {
                  if (result.data) {
                    setValue("option", result.data.option);
                  }
                } else {
                  toast.error(result.message);
                }
              })
              .catch((error) => {
                toast.error(error.response.data.message.replace(/_/g, " "));
              });
          };

          const onDelete = (id) => {
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              //Delete Product API
              if (willShip) {
                await DeleteSkipReason(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getSkipReasonFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };
          return (
            <div>
              <Tooltip title="Edit" arrow>
                <button
                  className={"btn btn-primary btn-sm"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit(params.id);
                  }}
                >
                  <MdEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  className={"btn btn-danger btn-sm mx-2"}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(params.id);
                  }}
                >
                  <MdDelete />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return obj;
    //setColumns(obj);
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "skip_quesition",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "skip_quesition",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("skip_quesition", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("skip_quesition", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray?.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onEdit = (id) => {
                    reset({
                      option: "",
                    });
                    setShowEditModal(true);
                    setSkipReasonId(id);
                    GetSkipReasonById(id)
                      .then((result) => {
                        if (result.status) {
                          if (result.data) {
                            setValue("option", result.data.option);
                          }
                        } else {
                          toast.error(result.message);
                        }
                      })
                      .catch((error) => {
                        toast.error(
                          error.response.data.message.replace(/_/g, " ")
                        );
                      });
                  };

                  const onDelete = (id) => {
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      //Delete Product API
                      if (willShip) {
                        await DeleteSkipReason(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getSkipReasonFunction();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            toast.error(
                              error.response.data.message.replace(/_/g, " ")
                            );
                          });
                      }
                    });
                  };
                  return (
                    <div>
                      <Tooltip title="Edit" arrow>
                        <button
                          className={"btn btn-primary btn-sm"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onEdit(params.id);
                          }}
                        >
                          <MdEdit />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <button
                          className={"btn btn-danger btn-sm mx-2"}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDelete(params.id);
                          }}
                        >
                          <MdDelete />
                        </button>
                      </Tooltip>
                    </div>
                  );
                },
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "skip_quesition",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}

      <div className="program-index">
        <h1>Skip Question Reasons</h1>
        <div className={"customOrderFilters"}>
          <Row>
            <Col md={1}></Col>
            <Col md={6} className="ms-auto">
              <div className="all-user">
                <div className="search-bar">
                  <div className="common-search-bar">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <SearchIcon />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setKeyword(e.target.value.trim())
                            : setKeyword(null);
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <Button
                className={"btn common-btn   w-100"}
                onClick={() => setShowAddModal(true)}
              >
                <AddIconOutline /> Add New
              </Button>
            </Col>
          </Row>
        </div>
        <div className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={onMultipleDelete}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              density="compact"
              loading={searchLoading}
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnWidthChange={handleColumnWidthChange}
              isColumnResizable
              onColumnVisibilityModelChange={toggleCustomFunc}
              columnVisibilityModel={columnVisibilityModel}
              apiRef={apiRef}
              onColumnOrderChange={handleColumnOrderChange}
            />
            <div className="user-pagination">
              <Row>
                <Col md={4}></Col>
                <Col md={4}>
                  {" "}
                  <Stack spacing={2}>
                    <Pagination
                      count={countPage}
                      page={page}
                      onChange={handlePaginationChange}
                      size="large"
                    />
                  </Stack>
                </Col>
                <Col md={4}>
                  {" "}
                  <Stack direction="row" spacing={2} alignItems="center">
                    <label>Rows per page:</label>
                    <Select value={perPage} onChange={handlePageSizeChange}>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </Stack>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <ThemeModal
          title={"Add New Option"}
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          size={"md"}
          content={<AddModal />}
        />
        <ThemeModal
          title={"Edit Option"}
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          size={"md"}
          content={<EditModal />}
        />
      </div>
    </>
  );
}

export default SkipQuestion;
